$main-blue: #649DFF;
$main-blue-hover: #5992f3;
$main-gray: #9DA2AA;
$main-black: #26292E;
$block-background-color: #F5F8FC;
$block-border-color: #E7EFFC;

#dashboard {
    max-width: 960px;
    padding: 8px;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 100px;

    h1 {
        color: $main-black;
    }

    @media (max-width: 720px) {
        h1 {
            font-size: 24px;
        }
    }

    .menus {
        display: flex;
        align-items: center;
        margin-top: 12px;
        gap: 4px;

        .menu {
            cursor: pointer;
            padding: 4px 8px;
            transition: 200ms;
            border-radius: 4px;

            &:hover {
                background-color: $block-background-color;
            }
        }

        .selected {
            background-color: $block-background-color;
        }
    }

    footer {
        margin-top: 80px;
        text-align: center;
        color: $main-gray;
        font-size: 14px;

        a {
            color: $main-blue;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

#dashboard-page {
    margin-top: 16px;

    .card {
        background-color: $block-background-color;
        border: solid 2px $block-border-color;
        border-radius: 12px;
        box-sizing: border-box;
        padding: 32px 24px;
        color: $main-black;

        h3 {
            font-weight: 800;
            font-size: 24px;
        }

        a {
            color: $main-blue;
            text-decoration: none;
            font-size: 14px;
            word-break: keep-all;

            &:hover {
                text-decoration: underline;
            }
        }

        .link {
            cursor: pointer;
            color: $main-blue;
            text-decoration: none;
            font-size: 14px;

            &:hover {
                text-decoration: underline;
            }
        }

        p {
            word-break: keep-all;
            font-size: 16px;
            color: $main-gray;
        }

        .icon {
            width: 44px;
            height: 44px;
            box-sizing: border-box;
            border-radius: 8px;
            background-color: $main-blue;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 8px;

            svg {
                width: 32px;
                height: 32px;
            }
        }

        .switch {
            // margin-top: 20px;
            margin-bottom: 20px;
            cursor: pointer;
            box-sizing: border-box;
            display: flex;
            padding: 0px 8px;
            align-items: center;
            border-radius: 64px;
            width: 100px;
            height: 50px;
            background-color: $main-blue;
            transition: 200ms;

            &-off {
                background-color: $main-gray;
            }

            .switch-in {
                background-color: #ffffff;
                width: 40px;
                height: 40px;
                border-radius: 20px;
                transition: 200ms;
                margin-left: 44px;

                &-off {
                    margin-left: 0px;
                }
            }
        }

        .accounts {
            .account {
                margin-top: 16px;
                display: flex;
                align-items: center;

                svg {
                    width: 24px;
                    height: 24px;
                    margin-right: 8px;
                }

                p {
                    font-size: 16px;
                    color: $main-black;
                }

                a {
                    font-size: 16px;
                }
            }
        }

        .timezone-select, .calendar-select {
            margin-top: 12px;
        }

        .input {
            display: flex;
            box-sizing: border-box;
            border: solid 1px hsl(0, 0%, 80%);;
            border-radius: 4px;
            justify-content: space-between;
            align-items: center;
            height: 36px;
            padding: 12px;
            background-color: #ffffff;
            margin-top: 12px;
            transition: 200ms;
    
            &:focus-within {
                box-shadow: 0 0 0 2px #2684ff;
            }
    
            input {
                border: 0;
                display: flex;
                flex: 1;
                padding-right: 4px;
    
                &:focus {
                    outline: none;
                }
        
                &::placeholder {
                    color: $main-gray;
                }
            }

            textarea {
                border: 0;
                width: 100%;
                min-height: 90px;
                resize: none;
                padding-right: 4px;

                &:focus {
                    outline: none;
                }
        
                &::placeholder {
                    color: $main-gray;
                }
            }
    
            .active {
                display: flex;
                align-items: center;
                cursor: pointer;
                svg {
                    width: 32px;
                    height: 32px;
    
                    polyline {
                        stroke: #649DFF;
                    }
    
                    circle {
                        stroke: #649DFF;
                    }
                }
            }
    
            .disabled {
                display: flex;
                align-items: center;
                svg {
                    width: 32px;
                    height: 32px;
    
                    polyline {
                        stroke: #dadada;
                    }
    
                    circle {
                        stroke: #dadada;
                    }
                }
            }
        }

        .input-textarea {
            align-items: flex-end;
            height: auto;
        }
    }

    .logout-menus {
        padding: 12px 36px;
        height: fit-content;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 800;

        div {
            padding: 4px 12px;
            border-radius: 4px;
            transition: 200ms;
            cursor: pointer;

            &:hover {
                background-color: $block-border-color;
            }
        }

        .warn {
            &:hover {
                background-color: #f15d5d;
                color: #ffffff;
                font-weight: 400;
            }
        }
    }

    .notice {
        display: block;
        margin-top: 8px;
        padding: 8px 16px;
        text-decoration: none;
        color: $main-blue;
    }

    .cards {
        margin-top: 16px;
        display: grid;
        gap: 16px;
        grid-template-columns: 1fr 1fr;

        @media (max-width: 720px) {
            grid-template-columns: 1fr;
        }
    }

    iframe {
        margin-top: 60px;
        width: 100%;
        height: 100vh;
        border: 0;
        border-radius: 16px;
        @media (max-width: 720px) {
            width: 100vw;
            margin-left: -8px;
        }
    }

    .plan-desc {
        font-size: 14px;
        color: $main-gray;
    }
}