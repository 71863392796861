@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', sans-serif;
}

body, html {
    width: 100vw;
    min-height: 100vh;
    overflow-x: hidden;
}

.underscore {
  box-sizing: border-box;
  display: inline-block;
  position: relative;
}

.underscore::after {
  content: "";
  display: block;
  background-color: rgba(63, 186, 241, 0.3);
  margin-top: -16px;
  height: 14px;
}