$main-blue: #649DFF;
$main-red-hover: #fcf1f1;
$main-blue-hover: #5992f3;
$main-gray: #9DA2AA;
$main-black: #26292E;
$block-background-color: #F5F8FC;
$block-border-color: #E7EFFC;

.loading {
    margin-top: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    @keyframes loading {
        0% {
            transform: translateY(0px);
        }

        100% {
            transform: translateY(20px);
        }
    }

    .dot1, .dot2, .dot3 {
        width: 10px;
        height: 10px;
        background-color: $main-blue;
        border-radius: 5px;
        animation: 0.9s cubic-bezier(0.5, 0, 0.5, 1) 0s infinite alternate loading ;
        
    }

    .dot1 {
        animation-delay: 0ms;
    }

    .dot2 {
        animation-delay: 200ms;
    }

    .dot3 {
        animation-delay: 400ms;
    }
}