$main-blue: #649DFF;
$main-red: #ff6464;
$main-red-hover: #fcf1f1;
$main-blue-hover: #5992f3;
$main-gray: #9DA2AA;
$main-black: #26292E;
$block-background-color: #F5F8FC;
$block-border-color: #E7EFFC;

.blur {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    z-index: 99;
    cursor: pointer;
}

@keyframes slideIn {
    0% {
        transform: translate(0, 20px);
    }

    100% {
        transform: translate(0, 0px);
    }
}

@keyframes slideIn-blur {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.modal-divver {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;

    align-items: center;
    @media (max-width: 720px) {
        align-items: flex-end;
    }
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    cursor: pointer;
    z-index: 999;
    animation: slideIn-blur 200ms cubic-bezier(0.04, 0.86, 1, 1);
}

#modal {
    color: $main-black;
    max-width: 375px;
    cursor: default;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 32px;
    @media (max-width: 720px) {
        padding: 36px 16px;
    }
    border-radius: 12px;
    z-index: 999;
    animation: slideIn 200ms cubic-bezier(0, 1, 1, 1);

    .title {
        font-size: 36px;
        font-weight: 800;
        @media (max-width: 720px) {
            font-size: 28px;
        }
    }

    .subtitle {
        margin-top: 20px;
        font-weight: 800;
        font-size: 24px;
        @media (max-width: 720px) {
            font-size: 20px;
        }
    }

    .desc {
        color: $main-gray;
        word-break: break-all;
    }

    .btn-link {
        display: block;
        color: #649DFF;
        text-decoration: none;
        padding: 8px;
        border-radius: 4px;
        transition: 200ms;
        &:hover {
            background-color: $block-background-color;
        }
    }

    .btn {
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;
        background-color: #ffffff;
        border: solid 2px $block-border-color;
        border-radius: 12px;
        padding: 12px 24px;
        box-sizing: border-box;
        transition: 200ms;
        margin-top: 100px;
        user-select: none;
        text-decoration: none;
        color: $main-black;

        &:hover {
            background-color: $block-background-color;
        }

        div {
            flex: 1;
            text-align: center;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    .btn-1 {
        color: $main-blue;
        font-weight: 800;
    }

    .btn-2 {
        margin-top: 4px;
        background-color: $main-blue;
        color: #ffffff;
        font-weight: 800;

        &:hover {
            background-color: $main-blue-hover;
        }
    }

    .btn-3 {
        border-color: $main-red;
        color: $main-red;
        font-weight: 800;
        &:hover {
            background-color: $main-red-hover;
        }
    }


    .input {
        display: flex;
        box-sizing: border-box;
        border: solid 2px $block-border-color;
        border-radius: 12px;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        padding: 12px;
        margin-top: 80px;

        &:focus-within {
            box-shadow: 0px 4px 4px $block-border-color;
        }

        input {
            border: 0;
            display: flex;
            flex: 1;
            padding-right: 4px;

            &:focus {
                outline: none;
            }
    
            &::placeholder {
                color: $main-gray;
            }
        }

        .active {
            display: flex;
            align-items: center;
            cursor: pointer;
            svg {
                width: 32px;
                height: 32px;

                polyline {
                    stroke: #649DFF;
                }

                circle {
                    stroke: #649DFF;
                }
            }
        }

        .disabled {
            display: flex;
            align-items: center;
            svg {
                width: 32px;
                height: 32px;

                polyline {
                    stroke: #dadada;
                }

                circle {
                    stroke: #dadada;
                }
            }
        }
    }

    .btn-desc {
        margin-top: 8px;
        text-align: center;
        font-size: 14px;
        color: $main-gray;



        a {
            color: $main-blue;
        }

        .link {
            cursor: pointer;
            color: $main-blue;
        }
    }
}