$main-blue: #649DFF;
$main-blue-hover: #5992f3;
$main-blue-active: #5089eb;
$main-gray: #9DA2AA;
$main-black: #26292E;
$block-background-color: #F5F8FC;
$block-background-hover-color: #dce9f8;
$block-border-color: #E7EFFC;

.plans {
    display: grid;
    gap: 12px;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    margin-top: 20px;

    @media (max-width: 720px) {
        flex-direction: column;
    }

    .plan {
        border: solid 2px #dddddd;
        border-radius: 12px;
        padding: 32px;
        max-width: 350px;
        box-sizing: border-box;
        transition: 200ms;

        &:hover {
            transform: translate(0, -8px);
        }

        h3 {
            font-size: 20px;
            font-weight: 800;
        }

        .price-month {
            font-size: 40px;
            font-weight: 800;

            .price-month {
                font-size: 14px;
                font-weight: 400;
            }
        }

        .price-year {
            color: $main-gray;
            font-size: 14px;
        }

        .desc {
            margin-top: 12px;
            color: $main-black;
            min-height: 72px;
            word-break: keep-all;
        }

        .options {
            margin-top: 32px;

            .option {
                display: flex;
                align-items: flex-start;
                gap: 8px;
                margin-top: 8px;
                color: $main-gray;

                svg {
                    width: 28px;
                    min-width: 28px;
                    height: 28px;
                    padding: 4px;
                    box-sizing: border-box;
                    border-radius: 16px;
                    background-color: $main-gray;

                    polyline {
                        stroke: #ffffff;
                    }
    
                    circle {
                        stroke: #ffffff;
                    }
                }
            }

            .option-selected {
                svg {
                    background-color: $main-blue;
                }
            }
        }

        .btn {
            cursor: pointer;
            margin-top: 40px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px;
            border-radius: 8px;
            box-sizing: border-box;
            border: solid 2px $main-blue;
            background-color: #ffffff;
            color: $main-blue;
            transition: 200ms;

            &:hover {
                background-color: $block-background-color;
            }

            &:active {
                background-color: $block-background-hover-color;
            }
        }

        .btn-selected {
            cursor: default;
            border: solid 1px $main-gray;
            background-color: $main-gray;
            color: #ffffff;

            &:hover {
                background-color: $main-gray;
            }

            &:active {
                background-color: $main-gray;
            }
        }
    }

    .plan-black {
        border: solid 2px $main-black;
        background-color: $main-black;
        color: #ffffff;

        .desc {
            color: $main-gray;
        }

        .btn {
            border: solid 2px $main-blue;
            background-color: $main-blue;
            color: #ffffff;

            &:hover {
                background-color: $main-blue-hover;
            }

            &:active {
                background-color: $main-blue-active;
            }
        }
    }
}