.dots {
    .dot1, .dot2 {
        border-radius: 50%;
        background: linear-gradient(to bottom, #649DFF, #9764FF);
        position: fixed;
        transition: 600ms ease;
        z-index: -1;
    
        @media (max-width: 720px) {
            display: none;
        }
    }
    
    .dot1 {
        width: 400px;
        height: 400px;
        left: -200px;
        bottom: 50px;
        z-index: 500;

        @media (max-width: 1350px) {
            left: -300px;
        }
        @media (max-width: 1150px) {
            left: -400px;
        }
    }
    
    .dot2 {
        width: 300px;
        height: 300px;
        right: -180px;
        top: -80px;
        z-index: 500;

        @media (max-width: 1200px) {
            right: -300px;
        }
    }
    
    .dot-move1 {
        width: 400px;
        height: 400px;
        left: -200px;
        bottom: calc(100vh - 200px);
        @media (max-width: 1350px) {
            left: -300px;
        }
        @media (max-width: 1150px) {
            left: -400px;
        }
    }
    
    .dot-move2 {
        width: 250px;
        height: 250px;
        right: -100px;
        top: calc(100vh - 150px);
        @media (max-width: 1270px) {
            right: -200px;
        }
        @media (max-width: 1060px) {
            right: -250px;
        }
    }
}

