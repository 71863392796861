$opize-green: #2D6560;
$main-blue: #649DFF;
$main-purple: #9764FF;
$main-blue-hover: #5992f3;
$main-blue-active: #5089eb;
$main-gray: #9DA2AA;
$main-black: #26292E;
$block-background-color: #F5F8FC;
$block-background-hover-color: #dce9f8;
$block-border-color: #E7EFFC;
$landing-block-white: #E7EFFC;

#index {
    max-width: 960px;
    margin: 0 auto;
    margin-top: 90px;
    margin-bottom: 200px;

    .titles {
        display: flex;
        flex-direction: column;
        align-items: center;

        .opize-projects {
            color: $opize-green;
        }

        h1 {
            font-size: 54px;
            font-weight: 700;
            text-align: center;

            @media (max-width: 720px) {
                font-size: 32px;
            }
        }

        .btn-connect {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 32px;
            background-color: $main-blue;
            color: #ffffff;
            border-radius: 8px;
            margin-top: 16px;
            transition: 200ms;

            &:hover {
                background-color: $main-blue-hover;
            }

            svg {
                height: 20px;
                width: auto;
            }
        }
    }

    

    .blocks-1 {
        display: flex;
        padding: 8px;
        gap: 16px;
        margin-top: 64px;

        @media (max-width: 720px) {
            flex-direction: column;
        }

        .block {
            border-radius: 20px;
            box-sizing: border-box;
            padding: 30px;

            h2 {
                line-height: 1.3;
                font-size: 40px;
            }

            h3 {
                line-height: 1.3;
                font-size: 32px;
            }

            p {
                margin-top: 4px;
                font-size: 20px;
            }

            @media (max-width: 720px) {
                h2 {
                    font-size: 30px;
                }
    
                h3 {
                    font-size: 28px;
                }
            }
        }

        .block-left {
            max-width: 550px;
            width: 100%;
            border-radius: 20px;
            background: linear-gradient(90deg, $main-blue, $main-purple);
            color: #ffffff;

            @media (max-width: 720px) {
                max-width: 100%;
            }

            .btn {
                padding: 8px 32px;
                border-radius: 30px;
                border: solid 2px #ffffff;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 8px 32px;
                width: 140px;
                margin-top: 30px;
                text-decoration: none;
                color: #ffffff;

                svg {
                    height: 20px;
                    width: auto;
                }
            }

            .img {
                
                display: flex;
                justify-content: flex-end;
                
                img {
                    margin-right: 0;
                    width: 192px;
                    height: 192px;
                }

                @media (max-width: 720px) {
                    margin-top: 32px;

                    img {
                        width: 128px;
                        height: 128px;
                    }
                }

            }
        }

        .block-right {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;
            flex: 1;

            .block-right-1 {
                background-color: $landing-block-white;
                height: 50%;
            }

            .block-right-2 {
                background-color: $main-black;
                color: $main-gray;
                height: 50%;
                p {
                    font-size: 14px;
                }
                
                span {
                    color: #ffffff;
                }
            }
        }
    }

    .blocks-2 {
        margin-top: 180px;
        @media (max-width: 720px) {
            margin-top: 90px;
        }
        padding: 8px;

        .title {
            font-size: 24px;
            font-weight: 800;
            color: $main-black;
            display: none;
            @media (max-width: 720px) {
                display: block;
            }
        }

        .block {
            margin-top: 16px;
            box-sizing: border-box;
            padding: 80px 60px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            @media (max-width: 720px) {
                flex-direction: column;
                padding: 40px 20px;
            }

            .block-left {
                width: 100%;
            }

            .block-right {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .subtitle {
                color: $main-blue;
                font-weight: 800;
                font-size: 24px;
                @media (max-width: 720px) {
                    font-size: 16px;
                }
            }

            h3 {
                font-size: 40px;
                color: $main-black;
                line-height: 1.3;
                @media (max-width: 720px) {
                    font-size: 30px;
                }
            }

            p {
                margin-top: 8px;
                color: $main-gray;
            }

            img {
                width: auto;
                height: 200px;
                @media (max-width: 720px) {
                    height: 140px;
                    margin-top: 20px;
                }
            }
        }

                
        .block-1 {
            background-color: $landing-block-white;
        }

        .block-2 {
            background: linear-gradient(90deg, $main-blue, $main-purple);

            .subtitle {
                color: $landing-block-white;
            }

            h3, p {
                color: #ffffff;
            }
        }


        .block-3 {
            background-color: $main-black;

            h3 {
                color: #ffffff;
            }
        }
    }

    .blocks-3 {
        margin-top: 270px;
        @media (max-width: 720px) {
            margin-top: 90px;
        }
        padding: 8px;

        .title {
            font-size: 24px;
            font-weight: 800;
            color: $main-black;
        }

        .reviews {
            display: flex;
            justify-content: space-between;
            margin-top: 10px;
            gap: 20px;
            @media (max-width: 720px) {
                flex-direction: column;
            }

            .review {
                width: 100%;
                padding: 20px;
                padding-bottom: 40px;
                box-sizing: border-box;
                background-color: $landing-block-white;
                border-radius: 20px;

                .review-emoji {
                    font-size: 32px;
                }

                .review-content {
                    margin-top: 10px;
                    font-size: 20px;
                    font-weight: 800;
                    word-break: keep-all;
                }

                .review-author {
                    margin-top: 10px;
                    font-size: 14px;
                    color: $main-gray;
                }
            }
        }
        
        .desc {
            margin-top: 8px;
            margin-left: 2px;
            font-size: 14px;
            color: $main-gray;

            span {
                color: #000000;
            }
        }
    }

    .blocks-4 {
        margin-top: 270px;
        @media (max-width: 720px) {
            margin-top: 90px;
        }
        padding: 8px;

        .title {
            font-size: 24px;
            font-weight: 800;
            color: $main-black;
        }

        .video {
            margin-top: 12px;
            position:relative;
            padding-bottom:56.25%;
            height:0;
            overflow:hidden;
            border-radius: 12px;
            box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.10);

            iframe, object, embed {
                border-radius: 12px;
                position:absolute;
                top:0;
                left:0;
                width:100%;
                height:100%;
            }
        }
    }

    .blocks-5 {
        margin-top: 270px;
        @media (max-width: 720px) {
            margin-top: 90px;
        }
        padding: 8px;
    }
    
    .blocks-6 {
        margin-top: 40px;
        text-align: center;
        img {
            width: 100px;
        }
    }
}
